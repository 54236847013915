import $ from 'jquery';
import InteractiveContentTooltip from './modules/InteractiveContentTooltip'

var interactiveLinks = document.getElementsByClassName('js-interactive-link')

if (interactiveLinks.length > 0) {
    document.querySelectorAll('.js-interactive-link').forEach(function(el) {
        new InteractiveContentTooltip(el)
    });
}
